define("discourse/plugins/migrate-users/discourse/controllers/admin-plugins-migrate-users", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPLuginsMigrateUsersController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "userCsv", [_tracking.tracked], function () {
      return "";
    }))();
    #userCsv = (() => (dt7948.i(this, "userCsv"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "groupCsv", [_tracking.tracked], function () {
      return "";
    }))();
    #groupCsv = (() => (dt7948.i(this, "groupCsv"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "userLogs", [_tracking.tracked], function () {
      return "";
    }))();
    #userLogs = (() => (dt7948.i(this, "userLogs"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "serverReceivedUsers", [_tracking.tracked], function () {
      return false;
    }))();
    #serverReceivedUsers = (() => (dt7948.i(this, "serverReceivedUsers"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "serverDestroyedLogs", [_tracking.tracked], function () {
      return false;
    }))();
    #serverDestroyedLogs = (() => (dt7948.i(this, "serverDestroyedLogs"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "errorMessage", [_tracking.tracked], function () {
      return "";
    }))();
    #errorMessage = (() => (dt7948.i(this, "errorMessage"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "filesUploaded", [_tracking.tracked], function () {
      return 0;
    }))();
    #filesUploaded = (() => (dt7948.i(this, "filesUploaded"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "allowUpload", [_tracking.tracked], function () {
      return false;
    }))();
    #allowUpload = (() => (dt7948.i(this, "allowUpload"), void 0))();
    getUserLogs() {
      this.serverDestroyedLogs = false;
      fetch("/userlogs.json").then(res => res.json()).then(json => {
        this.userLogs = json.userlogs;
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "getUserLogs", [_object.action]))();
    destroyLogs() {
      this.serverDestroyedLogs = false;
      this.serverReceivedUsers = false;
      fetch('/userlogs/destroy.json').then(res => res.json()).then(json => {
        if (json.status == 200) {
          this.serverDestroyedLogs = true;
          this.userLogs = "";
        }
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "destroyLogs", [_object.action]))();
    uploadUsersFile(event) {
      this.serverDestroyedLogs = false;
      this.serverReceivedUsers = false;
      this.errorMessage = "";
      if (event.target.files.length == 0) {
        this.errorMessage = "No files uploaded";
        this.filesUploaded = 0;
        this.allowUpload = false;
        return;
      } else if (event.target.files.length == 1) {
        this.userCsv = event.target.files[0];
        this.filesUploaded = 1;
        this.allowUpload = true;
      } else if (event.target.files.length == 2) {
        this.filesUploaded = 2;
        let fileArray = Array.from(event.target.files);
        this.userCsv = fileArray.find(file => file.name == "users.csv");
        this.groupCsv = fileArray.find(file => file.name == "groups.csv");
        if (this.userCsv == undefined || this.groupCsv == undefined) {
          this.errorMessage = "The names of the files uploaded must be 'users.csv' and 'groups.csv'.";
          this.allowUpload = false;
          return;
        } else {
          this.allowUpload = true;
        }
      } else {
        this.errorMessage = "Please only upload between one or two files";
        this.allowUpload = false;
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "uploadUsersFile", [_object.action]))();
    submitUsers(event) {
      this.serverDestroyedLogs = false;
      this.serverReceivedUsers = false;
      if (this.filesUploaded == 0) {
        this.errorMessage = "No files uploaded";
        return;
      } else if (this.filesUploaded == 1 && this.allowUpload) {
        const reader = new FileReader();
        reader.onload = () => {
          const file = reader.result;
          this.getCsrfToken().then(async csrf => {
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('X-CSRF-Token', csrf);
            let jsonResponse = await this.postData('/user_migrate_upload.json', {
              users: file
            }, myHeaders);
            if (jsonResponse.status == 200) {
              this.serverReceivedUsers = true;
            }
          });
        };
        reader.readAsText(this.userCsv);
      } else if (this.filesUploaded == 2 && this.allowUpload) {
        //Convert from FileList to array
        const userreader = new FileReader();
        const groupreader = new FileReader();
        userreader.onload = () => {
          //Reusing userCsv variable may be bad design, but it does work 
          this.userCsv = userreader.result;
          groupreader.readAsText(this.groupCsv);
        };
        groupreader.onload = () => {
          this.groupCsv = groupreader.result;
          this.getCsrfToken().then(async csrf => {
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('X-CSRF-Token', csrf);
            let jsonResponse = await this.postData('/user_migrate_upload.json', {
              users: this.userCsv,
              groups: this.groupCsv
            }, myHeaders);
            if (jsonResponse.status == 200) {
              this.serverReceivedUsers = true;
            }
          });
        };
        userreader.readAsText(this.userCsv);
      } else {
        this.errorMessage = "Something went wrong. Check you have supplied only 1 or 2 files and if 2 are supplied they are named 'users.csv' and 'groups.csv'.";
        return;
      }
    }
    static #_12 = (() => dt7948.n(this.prototype, "submitUsers", [_object.action]))();
    getCsrfToken() {
      return fetch('/session/csrf.json').then(res => res.json()).then(json => {
        return json.csrf;
      });
    }
    async postData() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let headers = arguments.length > 2 ? arguments[2] : undefined;
      return await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      }).then(res => {
        return res.json();
      }).then(json => {
        return json;
      });
    }
  }
  _exports.default = AdminPLuginsMigrateUsersController;
});